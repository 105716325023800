import { FC, HTMLAttributes, useRef } from 'react';

import classnames from 'classnames/bind';
import styles from './input.module.scss';
const cx = classnames.bind(styles);

interface InputProps extends Omit<HTMLAttributes<HTMLInputElement>, 'type' | 'className'> {
  withSearchIcon?: boolean;
  type?: string;
  containerClassName?: string;
  className?: string;
  afterElement?: React.ReactNode;
  value?: string;
  maxLength?: number;
  disabled?: boolean;
  error?: boolean;
}

const Input: FC<InputProps> = ({
  withSearchIcon,
  type,
  containerClassName,
  className,
  afterElement,
  value,
  maxLength,
  disabled,
  error,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!withSearchIcon || !inputRef.current) {
      return;
    }

    inputRef.current.focus();
  };

  return (
    <div className={cx('inputContainer', containerClassName)} onClick={handleClick}>
      {withSearchIcon && (
        <img src="/img/mypage/premium/iconSearchLight.png" alt="search icon" className={cx('searchIcon')} />
      )}
      <input
        ref={inputRef}
        type={type ?? 'text'}
        className={cx('input', className, { withIcon: withSearchIcon, disabled, error })}
        autoComplete="no"
        value={value}
        maxLength={maxLength}
        disabled={disabled}
        {...props}
      />
      {afterElement && <div className={cx('afterArea')}>{afterElement}</div>}
    </div>
  );
};

export default Input;
