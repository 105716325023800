import styles from '@common/content/content.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface ContentProps {
  children: React.ReactNode;
}

const Content = ({ children }: ContentProps) => {
  return <main className={cx('content')}>{children}</main>;
};

export default Content;
