import React, { FC, ReactNode, useState } from 'react';
import Modal from '@common/modal';
import CloseButton from '@common/closeButton';
import AllianceModal from '@components/common/modals/affiliateModal/affiliateList';
import { ContentRouteUtil } from '@utils/routeUtil';
import classNames from 'classnames/bind';
import styles from './noAffiliateAuthModal.module.scss';

const cx = classNames.bind(styles);

interface NoAffiliateAuthenticationModalProps {
  title: string | ReactNode;
  visible: boolean;
  onClose: () => void;
  onClickBg?: () => void;
}

/**제휴 회원 & 이벤트코드 인증 회원인 경우 접근 가능. */
const NoAffiliateAuthModal: FC<NoAffiliateAuthenticationModalProps> = ({ title, visible, onClose, onClickBg }) => {
  const [allianceModalVisible, setAllianceModalVisible] = useState(false);

  const onCheckAlliance = () => {
    onClose();
    setAllianceModalVisible(true);
  };

  const goMyPagePremiumPage = () => {
    ContentRouteUtil.goMyPage('premium');
  };

  return (
    <>
      <Modal visible={visible} onClickBg={() => onClickBg?.()}>
        <div className={cx('layerCertification')}>
          <strong className={cx('title')}>{title}</strong>
          <div className={cx('btnArea')}>
            <a className={cx('btn', 'certification')} role="button" onClick={goMyPagePremiumPage}>
              제휴 대학 및 인증 코드 입력하기
            </a>
            <a className={cx('btn', 'list')} role="button" onClick={onCheckAlliance}>
              제휴 리스트 확인하기
            </a>
          </div>
          <CloseButton className={cx('btnClose')} onClick={onClose} />
        </div>
      </Modal>
      <AllianceModal visible={allianceModalVisible} onClose={() => setAllianceModalVisible(false)} />
    </>
  );
};

export default NoAffiliateAuthModal;
