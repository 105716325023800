import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ContentsListItemVO } from '@domain/vo/common/ContentsListItemVO';
import { useUserInfo } from '@repository/auth/useUserInfo';
import useCheckAuthority from './useCheckAuthority';
import { PostJobContent } from '@domain/constant/postJob/PostJobContentType';
import { PrivacyEnum, PrivacyType } from '@domain/constant/privacyType';
import { ContentRouteUtil } from '@utils/routeUtil';

/**
 * 취업 콘텐츠 클릭했을 때 권한에 따라 로그인, 인증회원 모달 띄우는 로직 처리를 위한 Hook.
 * @param customQuery 인증이 필요한 콘텐츠일 경우 현재 페이지로 redirect하는 과정에서 추가하고 싶은 query 값.
 * @returns
 */
const useContentAccessControl = (customQuery?: string) => {
  const router = useRouter();
  const { asPath, query, events, push, replace } = router;
  const { open, infoSn, sn } = query;
  const { data: userInfo, isFetched } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { isAuthorityUser, isMatchingUser } = useCheckAuthority(userInfo);
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const [loginModalVisible, setLoginModalVisible] = useState(false);
  const [noAffiliateAuthModalVisible, setNoAffiliateAuthModalVisible] = useState(false);
  const [noMatchingUserModalVisible, setNoMatchingUserModalVisible] = useState(false);

  const handleNotLogin = (contentData: ContentsListItemVO, privacyType: PrivacyType) => {
    const hasQuery = !sn && Object.keys(query).length > 0;
    const nextUrl = `${asPath}${hasQuery ? '&' : '?'}open=${privacyType}&infoSn=${contentData.sn}${
      customQuery ? `&${customQuery}` : ''
    }`;
    setRedirectUrl(nextUrl);
    setLoginModalVisible(true);
    return false;
  };

  // 콘텐츠 클릭 시 회원공개 등 여부에 따라 true: 콘텐츠 세부로 이동, false: 페이지 이동하지 않음
  const handleCardClick = (contentData: ContentsListItemVO) => {
    if (contentData.type === PostJobContent.NEW_PAGE) {
      window.open(contentData.link, '_blank');
      return false;
    }

    switch (contentData.privacy) {
      // 전체 공개
      case 'PUBLIC':
        return true;

      // 회원 공개
      case 'PRIVATE':
        if (isLogin) return true;

        setRedirectUrl(contentData.routeUrl);
        setLoginModalVisible(true);
        return false;

      // 인증 회원 공개
      case 'PARTNERSHIP':
        if (!isLogin) return handleNotLogin(contentData, 'PARTNERSHIP');

        if (isAuthorityUser) return true;

        setNoAffiliateAuthModalVisible(true);
        return false;

      case 'MATCHING_USER':
        if (!isLogin) return handleNotLogin(contentData, 'MATCHING_USER');

        if (isMatchingUser) return true;

        setNoMatchingUserModalVisible(true);
        return false;
    }

    setRedirectUrl('');
    return false;
  };

  //로그인 후 리다이렉트 다음 동작 (제휴 인증 & 매칭 인재풀 관련 기능)
  useEffect(() => {
    if (!isFetched) return;
    if (isLogin) setLoginModalVisible(false);

    // asPath의 query 제거
    const url = new URL(asPath, 'http://dummyurl.com');
    const pathname = url.pathname;
    if (open === PrivacyEnum.PARTNERSHIP) {
      replace({ pathname }, undefined, { shallow: true });
      if (isAuthorityUser) {
        if (infoSn) push(ContentRouteUtil.getInfoDetailPage(`${infoSn}`));
      } else {
        setNoAffiliateAuthModalVisible(true);
      }
    }

    if (open === PrivacyEnum.MATCHING_USER) {
      replace({ pathname }, undefined, { shallow: true });
      if (isMatchingUser) {
        if (infoSn) push(ContentRouteUtil.getInfoDetailPage(`${infoSn}`));
      } else {
        setNoMatchingUserModalVisible(true);
      }
    }
  }, [router, isFetched]);

  useEffect(() => {
    const handleRouteChangeComplete = () => setLoginModalVisible(false);

    events.on('routeChangeComplete', handleRouteChangeComplete);
    return () => {
      events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [events]);

  return {
    handleCardClick,
    redirectUrl,
    loginModalVisible,
    setLoginModalVisible,
    /**제휴인증 관련 status */
    noAffiliateAuthModalVisible,
    setNoAffiliateAuthModalVisible,
    noAffiliateAuthModalTitle: '제휴서비스 인증이 필요해요.',
    /**인재풀 관련 status */
    noMatchingUserModalVisible,
    setNoMatchingUserModalVisible,
  };
};

export default useContentAccessControl;
