import React from 'react';
import Link from 'next/link';
import Icon from '@common/assets';
import { JOBDA_SUPPORT_EMAIL } from '@domain/constant/contact';
import Colors from '@domain/constant/colors';
import { RouteUrl } from '@utils/routeUtil';

import DateFormat from '@utils/date/format';
import classnames from 'classnames/bind';
import styles from './footer.module.scss';
const cx = classnames.bind(styles);

const FooterLogo = () => {
  return (
    <Link href={RouteUrl.INDEX} className={cx('logo')}>
      <Icon name="logo" width={80} height={18} fill={Colors.C_COOL_GRAY_70} />
      <span className={cx('hidden')}>JOBDA</span>
    </Link>
  );
};

const Footer = () => {
  const yearText = DateFormat.format(new Date(), 'yyyy');
  return (
    <footer className={cx('footer')}>
      <div className={cx('footerInner')}>
        <FooterLogo />

        <div className={cx('termsArea')}>
          <div className={cx('copyright')}>© {yearText} Copyright JAINWON</div>
          <Link href={RouteUrl.JOBDA_FAQ} className={cx('termsInfo')}>
            고객센터
          </Link>
          <Link href={RouteUrl.TERMS_OF_SERVICE} className={cx('termsInfo')}>
            서비스 이용약관
          </Link>
          <Link href={RouteUrl.PRIVACY} className={cx('termsInfo', 'emphasis')}>
            개인정보처리방침
          </Link>
        </div>

        <div className={cx('infoArea')}>
          <div className={cx('infoWrap')}>
            <span className={cx('infoText')}>자인원 대표자 신대석</span>
            <span className={cx('infoText')}>
              대표메일 <a href={`mailto:${JOBDA_SUPPORT_EMAIL}`}>{JOBDA_SUPPORT_EMAIL}</a>
            </span>
            <span className={cx('infoText')}>경기 성남시 분당구 판교로228번길 17, 판교세븐벤처밸리2 10층(삼평동)</span>
          </div>
          <div className={cx('infoWrap')}>
            <span className={cx('infoText')}>사업자번호 170-88-01418</span>
            <span className={cx('infoText')}>직업정보제공사업 신고번호 J1516020210006</span>
            <span className={cx('infoText')}>유료직업소개사업등록번호 : (국내) 제2021-3780288-14-5-00039호</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
