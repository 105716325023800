import React, { FC, useState } from 'react';
import Modal from '@common/modal';
import CloseButton from '@common/closeButton';
import Input from '@common/input';
import Tab from '@common/tab';
import Icon from '@components/common/assets';
import { useAffiliateList } from '@repository/partnership/usePartnership';
import { useUserInfo } from '@repository/auth/useUserInfo';
import { PARTNERSHIP_TYPE } from '@domain/rs/alliance/AffiliateListRs';
import { PATTERN } from '@utils/validators/pattern';
import classnames from 'classnames/bind';
import styles from './allianceModal.module.scss';

const cx = classnames.bind(styles);
interface AllianceListProps {
  list: string[];
  keyword: string;
}

const AllianceList: FC<Omit<AllianceListProps, 'keyword'>> = ({ list }) => {
  const listItem = list.map((item, index) => {
    return (
      <li key={index} className={cx('listItem')}>
        {item}
      </li>
    );
  });

  return <ul className={cx('allianceList')}>{listItem}</ul>;
};

const SearchedAllianceList: FC<AllianceListProps> = ({ list, keyword }) => {
  const listItem = list
    .filter((item) => item.includes(keyword))
    .map((item, index) => {
      const handleKeywordSymbol = keyword.replace(PATTERN.SEARCH_SPECIAL_SYMBOL, '\\$&');
      const regex = new RegExp(`${handleKeywordSymbol}`, 'g');
      const emphasisKeyword = item.replace(regex, `<span class=${cx('emphasis')}>${keyword}</span>`);

      return (
        <li
          key={index}
          className={cx('listItem')}
          dangerouslySetInnerHTML={{
            __html: emphasisKeyword,
          }}
        />
      );
    });

  return <ul className={cx('allianceList')}>{listItem}</ul>;
};

interface AffiliateModalProps {
  visible: boolean;
  onClose: (e: React.MouseEvent<HTMLElement>) => void;
}

const AffiliateModal: FC<AffiliateModalProps> = ({ visible, onClose }) => {
  const [keyword, setKeyword] = useState('');
  const [partnershipType, setPartnershipType] = useState<PARTNERSHIP_TYPE>('UNIVERSITY');
  const { data: userInfo } = useUserInfo();
  const isLogin = !!userInfo?.id;
  const { data } = useAffiliateList(partnershipType, isLogin);
  const affiliateList = data?.partnerships ?? [];
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
  };

  return (
    <Modal visible={visible} dimmed={true}>
      <div className={cx('layerAllianceModal')}>
        <strong className={cx('title')}>제휴 리스트</strong>
        <a className={cx('formLink')} href={'https://forms.office.com/r/QfCfGwfgBN'} target={'_blank'}>
          제안하기
          <Icon name={'arrowRightLight'} width={28} height={28} />
        </a>
        <CloseButton className={cx('btnClose')} onClick={onClose} />

        <Tab
          onTabChange={(e) => {
            setKeyword('');
            setPartnershipType(e as PARTNERSHIP_TYPE);
          }}
        >
          <Tab.Item title="제휴 대학" index="UNIVERSITY">
            <div className={cx('tabContainer')}>
              <p className={cx('description')}>
                제휴 대학 소속일 경우, <span className={cx('em')}>[제휴 대학]</span> 탭에서 소속된 학교명을 검색하여
                <br />
                이메일 인증을 통해 제휴인증을 완료해주시기 바랍니다.
              </p>
              <Input key="0" placeholder="제휴 학교를 검색해주세요." withSearchIcon onChange={handleChange} />
              <div className={cx('allianceListArea')}>
                {keyword === '' ? (
                  <AllianceList list={affiliateList} />
                ) : (
                  <SearchedAllianceList list={affiliateList} keyword={keyword} />
                )}
              </div>
            </div>
          </Tab.Item>
          <Tab.Item title="제휴 기관" index="ORGANIZATION">
            <div className={cx('tabContainer')}>
              <p className={cx('description')}>
                제휴 기관 소속일 경우, 제휴 기관에 문의하시어 인증코드를 부여받은 후,
                <br />
                <span className={cx('em')}>[인증코드 입력]</span>에 해당 인증코드를 입력하시어 제휴인증을 완료해주시기
                바랍니다.
              </p>
              <Input key="1" placeholder="제휴 기관을 검색해주세요." withSearchIcon onChange={handleChange} />
              <div className={cx('allianceListArea')}>
                {keyword === '' ? (
                  <AllianceList list={affiliateList} />
                ) : (
                  <SearchedAllianceList list={affiliateList} keyword={keyword} />
                )}
              </div>
            </div>
          </Tab.Item>
        </Tab>
      </div>
    </Modal>
  );
};

export default AffiliateModal;
